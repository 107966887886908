export enum EventTypeEnum {
  COMMENT_REPLY_ON_COMMENT = 1,
  COMMENT_ON_COMMUNITY_POST = 2,
  COMMENT_ON_COURSE_CREATED_BY_ME = 3,
  COMMUNITY_NEW_PENDING_POST = 4,
  COMMUNITY_POST_IS_PUBLISHED = 5,
  COMMUNITY_POST_IS_LIKED = 6,
  COMMENT_IS_LIKED = 7,
  MENTION_IN_COMMENT_ON_COMMUNITY_POST = 12,
}
