import { useRouter } from 'next/router'
import useSWR from 'swr'
import { useApiWrapperWithErrorValidation } from '../../../shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from '../../../shared/hooks/use-api-wrapper-with-error-validation/types'
import useUser, { isAuthorizedUser } from 'shared/hooks/use-user'
import { MemberInterface } from '../types/member-interface'

export default function useIsUserMember(
  path?: string,
  notFoundResponseHandler: (error: unknown) => void = () => {},
  shouldRetryOnError?: boolean,
) {
  const router = useRouter()
  const { user } = useUser()
  const { communityPath } = router.query

  const { fetcher } = useApiWrapperWithErrorValidation<RequestMethodsEnum.get, MemberInterface>({
    method: RequestMethodsEnum.get,
    notFoundResponseHandler,
  })

  const {
    isValidating: isLoading,
    data: member,
    mutate,
  } = useSWR(
    () => {
      if (user && isAuthorizedUser(user)) {
        return `/api/community/community/${communityPath || path}/member/${user.id}`
      }
    },
    fetcher,
    { shouldRetryOnError },
  )

  return {
    isUserMember: typeof member !== undefined ? !!member : undefined,
    isLoading,
    mutate,
  }
}
