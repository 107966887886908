import { ErrorBoundary, Provider } from '@rollbar/react'
import React, { ReactElement, ReactNode, useEffect } from 'react'
import '@systemeio/comments/build/Comments.css'
import { NextPage } from 'next'
import { appWithTranslation } from 'next-i18next'
import type { AppProps } from 'next/app'
import { Router } from 'next/router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { LogArgument } from 'rollbar'
import { SWRConfig } from 'swr'
import Toast from 'shared/components/toast'
import { getCommunityLayout } from 'modules/community/components/community-layout'
import NextI18nextConfig from '../../next-i18next.config'
import '../../styles/globals.css'

export type NextPageWithLayout = NextPage & {
  getLayout: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

function App({ Component, pageProps }: AppPropsWithLayout) {
  const rollbarConfig = {
    enabled: true,
    environment: process.env.NODE_ENV,
    accessToken: process.env.NEXT_PUBLIC_ROLLBAR_ACCESS_TOKEN,
    captureUncaught: false,
    captureUnhandledRejections: true,
    ignoredMessages: [
      'Script error.',
      'ResizeObserver loop completed with undelivered notifications.',
      'ResizeObserver loop limit exceeded',
      "Failed to execute 'insertBefore' on 'Node': The node before which the new node is to be inserted is not a child of this node.",
    ],
    checkIgnore: function (isUncaught: boolean, args: LogArgument[], item: any) {
      return item?.custom?.skip
    },
    payload: {
      code_version: process.env.NEXT_PUBLIC_CODE_VERSION,
      client: {
        javascript: {
          source_map_enabled: true,
        },
      },
    },
  }

  useEffect(() => {
    const handleRouteStart = () => NProgress.start()
    const handleRouteDone = () => NProgress.done()

    Router.events.on('routeChangeStart', handleRouteStart)
    Router.events.on('routeChangeComplete', handleRouteDone)
    Router.events.on('routeChangeError', handleRouteDone)

    return () => {
      // Make sure to remove the event handler on unmount!
      Router.events.off('routeChangeStart', handleRouteStart)
      Router.events.off('routeChangeComplete', handleRouteDone)
      Router.events.off('routeChangeError', handleRouteDone)
    }
  }, [])

  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout ?? getCommunityLayout

  return (
    <>
      <Provider config={rollbarConfig}>
        <ErrorBoundary>
          <SWRConfig
            value={{
              revalidateOnFocus: false,
              revalidateIfStale: false,
              shouldRetryOnError: false,
            }}
          >
            {getLayout(<Component {...pageProps} />)}
            <Toast />
          </SWRConfig>
        </ErrorBoundary>
      </Provider>
    </>
  )
}

// @ts-ignore
export default appWithTranslation(App, NextI18nextConfig)
