import { Dialog, DialogPanel, Transition, TransitionChild } from '@headlessui/react'
import React, { Fragment, PropsWithChildren, useState } from 'react'
import { useTranslation } from 'next-i18next'
import { twMerge } from 'tailwind-merge'
import Avatar from '../../shared/components/avatar'
import LinkWithoutPrefetch from 'shared/components/link-without-prefetch'
import NotificationMenuMobile from 'shared/components/notification-menu/notification-menu-mobile'
import PrimaryButton from 'shared/components/primary-button'
import { ScreenHeightDiv } from 'shared/components/screen-height-div'
import useUser, { isAuthorizedUser, isCustomer } from 'shared/hooks/use-user'
import ArrowLeftIcon from 'shared/icons/arrow-left'
import BurgerIcon from 'shared/icons/burger-icon'
import LogoutIcon from 'shared/icons/logout-icon'
import { isCustomDomain } from 'shared/types/is-custom-domain'
import { getLoginUrl } from 'shared/utils/get-login-url'
import { useCommunityWithTopics } from 'modules/community/hooks/use-community-with-topics'
import { addPublicCustomClassNamePrefix } from 'modules/course/utils/add-class-prefix'
import LanguageSwitcher, { MobileLanguageSwitcher } from './components/language-switcher'
import { useAvailableSections } from './hooks/use-available-sections'
import { Menu } from './menu'
import { SideMenuItem } from './menu/side-menu-item'
import { getDashboardLink, getShowDashboardLink } from './menu/utils'
import { WorkspaceSelector } from './workspace-selector'

interface HeaderProps {
  logoUrl?: string
  className?: string
  desktopMenuItemClassName?: string
  desktopActiveMenuItemClassName?: string
  avatarLinkClassName?: string
  avatarPopupClassName?: string
  mobileMenuItemClassName?: string
  burgerClassName?: string
  languageSwitcherClassName?: string
  listLanguageSwitcherClassName?: string
  listLanguageSwitcherItemClassName?: string
  mobileSideMenuClassName?: string
  menuIconClassName?: string
  activeMenuIconClassName?: string
  headerContainerClassName?: string
}

function Header({
  logoUrl,
  className,
  desktopMenuItemClassName,
  desktopActiveMenuItemClassName,
  avatarLinkClassName,
  avatarPopupClassName,
  mobileMenuItemClassName,
  burgerClassName,
  languageSwitcherClassName,
  listLanguageSwitcherClassName,
  listLanguageSwitcherItemClassName,
  mobileSideMenuClassName,
  menuIconClassName,
  activeMenuIconClassName,
  headerContainerClassName,
}: HeaderProps) {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const { user } = useUser()
  const { data } = useCommunityWithTopics()
  const { data: availableSections } = useAvailableSections()

  function close() {
    setIsOpen(false)
  }

  const shouldRenderDashboardLink = getShowDashboardLink(user)
  const dashboardLink = getDashboardLink(user)

  const LogoLink = ({ children }: PropsWithChildren<{}>) =>
    user && logoUrl ? (
      !isCustomDomain() && shouldRenderDashboardLink ? (
        <a
          className={(!shouldRenderDashboardLink && 'pointer-events-none') || ''}
          href={dashboardLink}
        >
          {children}
        </a>
      ) : (
        <LinkWithoutPrefetch href={availableSections?.courses ? '/school' : '/community'}>
          {children}
        </LinkWithoutPrefetch>
      )
    ) : null

  return (
    <>
      <nav
        className={`top-0 z-20 bg-darkblue font-avertaPE ${
          className || ''
        } ${addPublicCustomClassNamePrefix('header')}`}
      >
        <div
          className={twMerge(
            'z-10 mx-auto flex h-[69px] w-full items-center justify-between px-4 text-white sm:px-6 md:px-14',
            headerContainerClassName,
          )}
        >
          <div className="flex items-center gap-5">
            <LogoLink>
              <img
                className={`max-h-[35px] ${addPublicCustomClassNamePrefix('header__logo')}`}
                src={logoUrl}
                alt="Systeme.io"
              />
            </LogoLink>

            <span className="font-bold">{data?.name}</span>
            <WorkspaceSelector
              className={addPublicCustomClassNamePrefix(`header__workspace-selector`)}
              position="top-menu"
            />
          </div>
          <div className="ml-auto flex h-full items-center gap-4 lg:mr-5">
            {user &&
              (isAuthorizedUser(user) ? (
                <div className="flex items-center gap-5 lg:hidden">
                  <NotificationMenuMobile
                    isCustomer={isCustomer(user)}
                    iconClassname={menuIconClassName}
                    activeIconClassname={activeMenuIconClassName}
                  />
                  <button
                    className={`mr-2 ${!user && 'animate-pulse'} ${addPublicCustomClassNamePrefix(
                      'header__burger-menu',
                    )}`}
                    aria-label="Open Menu"
                    onClick={() => setIsOpen(true)}
                  >
                    <BurgerIcon className={`h-8 w-8 ${burgerClassName || ''}`} />
                  </button>
                </div>
              ) : (
                <PrimaryButton className="bg-blue px-6" href={getLoginUrl(user.dashboardLocale)}>
                  {t('user.profile.menu.login')}
                </PrimaryButton>
              ))}
            <div className="flex h-full">
              <Menu
                className={`hidden gap-8 lg:flex ${addPublicCustomClassNamePrefix(
                  'desktop-navbar',
                )}`}
                menuIconClassName={menuIconClassName}
                activeMenuIconClassName={activeMenuIconClassName}
                menuItemClassName={desktopMenuItemClassName}
                menuItemActiveClassName={desktopActiveMenuItemClassName}
              />
            </div>
          </div>
          <div className="hidden h-full items-center justify-around gap-4 lg:flex">
            <Avatar linkClassName={avatarLinkClassName} popupClassName={avatarPopupClassName} />
            <LanguageSwitcher
              className={languageSwitcherClassName}
              listClassName={listLanguageSwitcherClassName}
              listItemClassName={listLanguageSwitcherItemClassName}
            />
          </div>
          {user && isAuthorizedUser(user) && (
            <Transition show={isOpen} as={Fragment}>
              <Dialog
                className={`fixed inset-0 z-20 font-avertaPE ${addPublicCustomClassNamePrefix(
                  'mobile-side-nav-menu__container',
                )}`}
                onClose={close}
              >
                <TransitionChild
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 bg-black/50" />
                </TransitionChild>

                <TransitionChild
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="ease-in duration-200"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <DialogPanel>
                    <ScreenHeightDiv
                      className={`fixed right-0 top-0 z-10 flex min-w-[17rem] flex-col overflow-auto bg-white px-4 py-5 text-darkblue ${mobileSideMenuClassName || ''}`}
                    >
                      <div
                        onClick={close}
                        className={`flex w-fit cursor-pointer items-center gap-2 ${
                          mobileMenuItemClassName || ''
                        } ${addPublicCustomClassNamePrefix('mobile-side-nav-menu__close-button')} `}
                      >
                        <ArrowLeftIcon className="stroke-darkblue" />
                        <span>{t('mobile_nav.back_to_page')}</span>
                      </div>
                      <div
                        className={`flex items-center gap-3.5 py-6 ${addPublicCustomClassNamePrefix(
                          'mobile-side-nave-menu__avatar',
                        )}`}
                      >
                        <a
                          href={
                            user?.workspaceOwner
                              ? '/dashboard/profile/account-settings'
                              : '/profile/user-settings'
                          }
                          className="flex items-center gap-3.5"
                        >
                          <img
                            className="h-10 w-10 rounded-full object-cover"
                            src={user?.avatarUrl}
                            alt={`${user?.firstName} ${user?.lastName}`}
                          />
                          <div className="flex flex-col">
                            <span className="text-base font-semibold">
                              {user?.firstName} {user?.lastName}
                            </span>
                            <span>{t('mobile_nav.settings')}</span>
                          </div>
                        </a>
                      </div>

                      <Menu
                        isMobile
                        className={`flex flex-col gap-8 py-4 text-lg text-bluegray ${addPublicCustomClassNamePrefix(
                          'mobile-navbar',
                        )}`}
                        menuItemClassName={mobileMenuItemClassName}
                      />

                      <div className="mt-auto flex flex-col gap-3">
                        <WorkspaceSelector
                          className={`${addPublicCustomClassNamePrefix(
                            'mobile-side-nav-menu__workspace-selector',
                          )}`}
                          position="side-menu"
                        />
                        {user?.impersonator && (
                          <a href="/dashboard/admin/management/user/list?pretend_this_user=_exit">
                            <p className="cursor-pointer px-2">{t('header.exit_impersonation')}</p>
                          </a>
                        )}
                        <div className="flex">
                          <SideMenuItem
                            className={`${mobileMenuItemClassName} ${addPublicCustomClassNamePrefix(
                              'mobile-side-nav-menu__logout-button',
                            )}`}
                            icon={<LogoutIcon />}
                            name="Logout"
                            href="/dashboard/logout"
                          />
                          <div
                            className={`ml-auto ${addPublicCustomClassNamePrefix(
                              'mobile-side-nav-menu__language-switcher',
                            )}`}
                          >
                            <MobileLanguageSwitcher
                              className={languageSwitcherClassName}
                              listClassName={listLanguageSwitcherClassName}
                              listItemClassName={listLanguageSwitcherItemClassName}
                            />
                          </div>
                        </div>
                      </div>
                    </ScreenHeightDiv>
                  </DialogPanel>
                </TransitionChild>
              </Dialog>
            </Transition>
          )}
        </div>
      </nav>
    </>
  )
}

export default Header
